import modal from './modal';

const header = document.querySelector('.m-header');
const btnMenu = header?.querySelector('.btn-menu');
const btnMenuClose = header?.querySelector('.btn-menu-close');
const btnCart = header?.querySelector('.btn-cart');
const btnLogin = header?.querySelectorAll('.btn-login');
const btnAccount = header?.querySelectorAll('.btn-account');
const body = document.body;

btnMenu?.addEventListener('click', () => {
  body?.classList.toggle('menu-is-open');
});

btnMenuClose?.addEventListener('click', () => {
  body?.classList.remove('menu-is-open');
});

btnCart?.addEventListener('click', (e) => {
  e.preventDefault();
  modal.showCart();
});

btnLogin?.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    modal.showLogin();
    body?.classList.remove('menu-is-open');
  });
});

btnAccount?.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    modal.showAccount();
    body?.classList.remove('menu-is-open');
  });
});